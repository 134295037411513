import * as React from "react"
import { graphql } from "gatsby"
import CanvasLayout from "../../components/layout/CanvasLayout"
import Container from "../../components/layout/Container"
import Type from "../../components/typography/Type"
import { isLoggedIn } from "../../services/auth"
import LoginPrompt from "../../components/app/loginPrompt"
import UserNeedCard from "../../components/cards/UserNeedCard"
import FeatureCard from "../../components/cards/FeatureCard"
import Toggle from "../../components/controls/Toggle"
import Layout from "../../components/layout/Layout"
import ProcessCard from "../../components/cards/ProcessCard"
import Collapsed from "../../components/controls/Collapsed"

import HorizonCard from "../../components/cards/HorizonCard"

import CanvasToolbar from "../../components/canvas/CanvasToolbar"
import Canvas from "../../components/canvas/Canvas"



import { useState } from "react"



export default function Pages( {data}) {

const [isOpen, toggleOpen] = useState(false);

return (
  <>
  <CanvasLayout>
  {isLoggedIn() ? (

<div>

  <CanvasToolbar>
    <div><a href={data.airtable.data.ProjectPath}><span className="">&lt; Back to {data.airtable.data.ProjectTxt}</span></a></div>
  </CanvasToolbar>

  <Canvas title={data.airtable.data.Name} introduction={data.airtable.data.Introduction}>  
  
  <div>
  {data.airtable.data.Releases
  ?

  <div className="flex flex-col lg:flex-row px-16 py-32 space-x-16">
  
  {data.airtable.data.Releases.map((ReleasesArray, index) => (
  <div>
    <Type type="h4">{ReleasesArray.data.Name}</Type>
    <Type type="p5">{ReleasesArray.data.ReleaseDate}</Type>
    <HorizonCard horizon={ReleasesArray.data.HorizonTxt}/>
    <div className="p-8 bg-blue min-w-[320px] p-8">
      <div className="flex flex-col lg:flex-row space-x-8">
        {ReleasesArray.data.Objectives
        ?
        <>
        {ReleasesArray.data.Objectives.map((ObjectivesArray,index) => (
          <div className="min-w-[320px]">
          <Type type="h5">{ObjectivesArray.data.Name}</Type>
          {ObjectivesArray.data.Products
            ?
            <div>
            {ObjectivesArray.data.Products.map((ProductsArray) => (
              <>
              
              {ProductsArray.data.Capabilities
                ?
            <div>
                {ProductsArray.data.Capabilities.map((CapabilitiesL2Array) => (
                  <>
                  <div className="bg-white p-4 rounded-lg mb-8">
                  <Type type="p5" nopadding="yes">{CapabilitiesL2Array.data.Name}</Type>
                  
                  <div>
                    {CapabilitiesL2Array.data.Products
                      ?
                      <Collapsed title="Products" titlesize="p6">
                        <div className="max-w-fit">
                          <div className="grid grid-cols-1 gap-4">
                          {CapabilitiesL2Array.data.Products.map((JourneysArray) => (
                            <FeatureCard url={JourneysArray.data.Path} title={JourneysArray.data.Name}/>
                          ))}
                          </div>
                        </div>
                      </Collapsed>
                      :
                      undefined
                    }
                  </div>
                  
                  
                  </div>
                  
                  </>
                ))}
            </div>
                
                
                :
                
                undefined
              }
              
              
              
            </>
              
            ))}
            
            </div>
            :
            <div>No Products defined</div>
          }
          </div>
  
        ))}
        </>
        :
        <div>No release objectives defined</div>
        }
      </div>
    </div>
  </div>
  ))}
  </div>
  :
  <div>No releases defined</div>
  }
  </div>
  </Canvas>

</div>
   
   ) : (
     <div>
       <LoginPrompt/>
     </div>     
   )}
  </CanvasLayout>
    </>

  )
}

export const Head = () => <title>Journey</title>


export const query = graphql`
  query GetPage($Path: String!) {
    airtable(table: { eq: "HorizonMaps" },
    data: { Path: { eq: $Path } }) {
      data {
        Name
        ProjectPath
        ProjectTxt
        Releases {
          data {
            Name
            ReleaseDate
            HorizonTxt
            Objectives {data {
              Name
              Products {data{
                Name
                Path
                Capabilities {data {
                  Name
                  JourneysTxt
                  Products {data{
                    Name
                    Path
                  }}
                }}
              }}
            }}
          }
        }
      }
    }
  }
`
